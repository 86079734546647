import React, { useEffect } from 'react';
import Router from 'next/router';

const HomePage = () => {
    useEffect(() => {
        Router.push('/marketplace');
    }, []);
    return <></>;
};

export default HomePage;